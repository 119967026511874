import Axios from "axios";
import React, { Component, useEffect, useRef, useState } from "react";
import AuthContainer from "../../../../components/AuthContainer";
import AppContext from "../../../../context/store";
import Button from "../../../../theme/Button";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import Input from "../../../../theme/CustomMUI/Input";
import { Grid, Column } from "../../../../theme/Grid";
import {
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
} from "@material-ui/core";
import FCLabel from "../../../../theme/FCLabel";
import Table from "../../../../theme/Table";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styled from "styled-components";
import Chip from "@material-ui/core/Chip";
import { Alert } from "@material-ui/lab";
import { toast } from "react-toastify";
import CalculateMessageCharCount from "../../../../functions/CalculateMessageCharCount";
import CalculateMessageCount from "../../../../functions/CalculateMessageCount";
import Select from "../../../../theme/CustomMUI/Select";
import SMSReview from "../../../../components/Dialogs/SMSReview";
import BetweenTimeGenerator from "../../../../functions/BetweenTimeGenerator";
import moment from "moment";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import TimeSelect from "../../../../theme/TimeSelect";
import { Delete, FeaturedPlayList, WhatsApp } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import UndefinedSenderAlert from "../../../../components/Alerts/UndefinedSenderAlert";
import WpUndefinedAlert from "../../../../components/Alerts/WpUndefinedAlert";
import UploadInput from "../../../../components/UploadInput";
import { List } from "react-virtualized";
import AutoSizer from "react-virtualized-auto-sizer";
import loader from "../../../../assets/images/loader.gif";

class Group extends Component {
  static contextType = AppContext;

  state = {
    step: 0,
    search_key: "",
    loaded: false,

    pagination: {
      total_page: null,
      page: null,
      onChange: (page) => this.getGroups(page),
    },

    groups: [],
    available_credit: null,

    selected_groups: [],
    excepted_groups: [],
    all_groups: false,
    message: "",
    send_date: new Date(), //Gönderilecek tarih
    selected_time: null, //Gönderilecek saat
    hours: [...BetweenTimeGenerator(this.context.state.company_shift)],
    future_date: false,

    customers: [],
    selected_customers: [],
    selected_customer_allpage: [],

    templates: [],
    selected_template: null,

    new_group: {
      edited: false,
      selected_group: null,

      name: null,

      pagination: {
        total_page: null,
        page: 1,
        onClick: (page) => this.searchCustomersByKey(page),
      },

      select_all_customers: false,
    },

    open_new_group_dialog: false,
    open_send_sms_dialog: false,

    open_sms_review_dialog: false,
    total_number: null,
    total_sms_credit: null,
    total_wp_credit: null,

    shortCode: null,

    sender: "",

    // Checknox for sms and wp
    checkedSMS: true,
    checkedWP: null,

    // whatsapp or sms
    api_key: "",

    // Balance
    wp_balance: 0,

    // WPState and WP phone
    wp_state: false,
    wp_phone: null,

    send_file: null,
    loader_customer_list: false,
  };

  fileHandlerProp = (value) => {
    this.setState({
      send_file: value,
    });
  };

  // checking dor wp accounts
  checkWp = async () => {
    await Axios.get(
      `${this.context.api_endpoint}/company/get/wp/message/settings`
    )
      .then(async (ress) => {
        if (ress.data.data.device_key !== "" && ress.data.data.device_key) {
          await Axios.post(
            `${this.context.api_endpoint}/salon/wp/login/check/active/${ress.data.data.device_key}`
          )
            .then((ress) => {
              if (ress.status === 200) {
                this.setState({
                  wp_state: ress.data.is_connect,
                  wp_phone: ress.data.data.device_number,
                  checkedWP: true,
                });
              }
            })
            .catch(() => {
              toast.warn("The device is not active, please update!");
              this.setState({
                wp_state: false,
                wp_phone: null,
                checkedWP: false,
              });
            });
        } else {
          this.setState({
            checkedWP: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getGroups = (page = 1) => {
    Axios.get(
      `${this.context.api_endpoint}/company/group/list?page=${page}&name=${this.state.search_key}`
    )
      .then(({ data }) => {
        this.setState({
          loaded: true,
          groups: [...data.data.records],
          pagination: {
            ...this.state.pagination,
            total_page: data.data.records.length,
            page: data.data.page,
          },
        });
      })
      .catch(() => {
        this.props.history.push("/wallet");
      });
  };

  getCustomers = (page = 1) => {
    Axios.get(
      `${this.context.api_endpoint}/company/customers/blacklist?page=${page}`
    ).then(({ data }) => {
      this.setState({
        loaded: true,
        customers: [...data.data.records],
      });
    });
  };

  searchCustomersByKey = async (page = 1, key) => {
    await Axios.get(
      `${this.context.api_endpoint}/company/customers?page=${page}`,
      {
        params: { key },
      }
    ).then(({ data }) => {
      this.setState({
        new_group: {
          ...this.state.new_group,
          pagination: {
            ...this.state.new_group.pagination,
            total_page: data.data.records.length,
            page: data.data.page,
          },
        },
        customers: [...data.data.records],
      });
    });
  };

  addNewGroup = () => {
    Axios.post(`${this.context.api_endpoint}/company/group/insert`, {
      name: this.state.new_group.name,
      all: this.state.new_group.select_all_customers,
      customers_id: [
        ...this.state.selected_customers.map((customer) => customer.id),
      ],
    })
      .then((response) => {
        toast.success(this.context.t(`['sms/group'].newGroup.successToast`));
        this.setState({
          selected_customers: [],
          open_new_group_dialog: false,
        });
        this.getGroups();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  sendSMS = () => {
    const {
      selected_time,
      send_date,
      all_groups,
      selected_groups,
      future_date,
      excepted_groups,
      message,
    } = this.state;
    let appointment_date = null;
    if (future_date === true) {
      let appointment_time = selected_time.split(":");
      appointment_date = `${moment(send_date).format("YYYY-MM-DD")}T${
        appointment_time[0]
      }:${appointment_time[1]}:00Z`;
    }
    let sms_prefer = (() => {
      if (this.state.checkedSMS && this.state.checkedWP) {
        return 3;
      }
      if (this.state.checkedSMS) {
        return 1;
      } else {
        if (this.state.checkedWP) {
          return 2;
        } else {
          return 0;
        }
      }
    })();
    if (sms_prefer === 0) {
      return toast.error("Gönderim yöntemlerinden birisini seçin");
    }

    const formData = new FormData();
    formData.append("all", all_groups);
    selected_groups.forEach((group) => {
      formData.append("group_id", group.id);
    });
    formData.append("except", []);
    formData.append("message", message);
    formData.append("sms_prefer", 2);

    formData.append("file", this.state.send_file);

    future_date
      ? formData.append("send_date", appointment_date)
      : formData.append("send_date", "");

    Axios.post(`${this.context.api_endpoint}/company/group/send/sms`, formData)
      .then(({ data }) => {
        if (data.status === 200 || data.status === 201) {
          toast.success(data.message);
          this.setState({
            step: 0,
            selected_groups: [],
            excepted_groups: [],
            all_groups: false,
            message: "",
            send_date: new Date(),
          });
          this.getGroups();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getGroupDetail = () => {
    Axios.get(`${this.context.api_endpoint}/company/group/detail`, {
      params: {
        groupid: this.state.new_group.selected_group.id,
      },
    }).then(({ data }) => {
      this.setState({
        new_group: {
          ...this.state.new_group,
          name: data.data.name,
        },
      });
    });
  };

  getGroupCustomers = () => {
    this.setState({ loader_customer_list: true });
    Axios.get(`${this.context.api_endpoint}/company/group/customer/list`, {
      params: {
        groupid: this.state.new_group.selected_group.id,
        page: -1,
      },
    })
      .then(({ data }) => {
        this.setState({
          selected_customers: data.data,
        });
      })
      .finally(() => this.setState({ loader_customer_list: false }));
  };

  updateGroup = async () => {
    const { new_group } = this.state;
    await Axios.put(`${this.context.api_endpoint}/company/group/update`, {
      group_id: new_group.selected_group.id,
      name: new_group.name,
      all: this.state.new_group.select_all_customers,
      customers_id: [
        ...this.state.selected_customers.map((customer) => customer.id),
      ],
    })
      .then(({ data }) => {
        toast.success(
          this.context.t(`['sms/group'].newGroup.edit.updateSuccessToast`)
        );
        this.setState({
          step: 0,
          search_key: "",
          groups: [],
          selected_groups: [],
          excepted_groups: [],
          all_groups: false,
          message: "",
          new_group: {
            ...this.state.new_group,
            name: "",
          },
          open_new_group_dialog: false,
        });
        this.getGroups();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  deleteGroup = () => {
    Axios.post(`${this.context.api_endpoint}/company/group/delete`, {
      group_id: this.state.new_group.selected_group.id,
    })
      .then(async ({ data }) => {
        toast.success(
          this.context.t(`['sms/group'].newGroup.edit.deleteSuccessToast`)
        );
        await this.setState({
          step: 0,
          search_key: "",
          groups: [],
          selected_groups: [],
          excepted_groups: [],
          all_groups: false,
          message: "",
          open_new_group_dialog: false,
          selected_customers: [],
          new_group: {
            ...this.state.new_group,
            edited: false,
            selected_group: false,
            select_all_customers: false,
            name: "",
            pagination: {
              ...this.state.new_group.pagination,
              page: 1,
            },
          },
        });
        this.getGroups();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  _rowRenderer = ({ index, key, style }) => {
    const customer = this.state.selected_customers[index];
    return (
      <div key={key} style={style}>
        {customer.name !== undefined && customer.surname !== undefined && (
          <Chip
            key={customer.id + "" + index}
            style={{
              marginBottom: "7px",
              width: "90%",
              display: "flex",
              justifyContent: "space-between",
            }}
            label={`${customer.name} ${customer.surname}`}
            onDelete={() => {
              this.setState({
                selected_customers: [
                  ...this.state.selected_customers.filter(
                    (_customer) => _customer.id !== customer.id
                  ),
                ],
              });
            }}
          />
        )}
      </div>
    );
  };

  getTemplates = () => {
    Axios.get(`${this.context.api_endpoint}/company/sms/template/all`).then(
      ({ data }) => {
        this.setState({
          templates: [...data.data],
        });
      }
    );
  };

  getMessageSettings = () => {
    Axios.get(`${this.context.api_endpoint}/company/message/credit`).then(
      ({ data }) => {
        this.setState({
          available_credit: `${data.data.credit} SMS`,
          sender: data.data.settings.sender,
        });
        if (
          data.data.settings.sender === "" ||
          data.data.settings.sender === null
        ) {
          this.setState({
            checkedSMS: false,
          });
        }
      }
    );
  };

  getSMSReview = () => {
    Axios.post(`${this.context.api_endpoint}/company/group/send/sms/preview`, {
      all: this.state.all_groups,
      group_id: [...this.state.selected_groups.map((group) => group.id)],
      except: [...this.state.excepted_groups.map((group) => group.id)],
      message: this.state.message,
      sms_prefer: 2,
    })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            total_number: response.data.data.total_numbers,
            total_wp_credit: response.data.data.total_wp_credit,
            open_sms_review_dialog: true,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getShortcode = () => {
    Axios.get(`${this.context.api_endpoint}/company/shortcode/list/keyword`)
      .then(async (response) => {
        if (response.status === 200) {
          await this.setState({
            shortCode: response.data.data.keyword,
          });
        }
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getGroups();
    this.getCustomers();
    this.getTemplates();
    this.getMessageSettings();
    this.checkWp();
  }

  render() {
    const AddGroupDialog = this._AddGroupDialog;
    return (
      <AuthContainer authorities={[1]} limited_permission="sms">
        {this.state.open_new_group_dialog && <AddGroupDialog />}
        <Grid className="mb-2">
          <Column className="xs-12 sm-12 md-auto">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-green bg-green-opacity">
                    <WhatsApp
                      style={{
                        fontSize: "3em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>
                      {this.context.t(`sms.index.whatsAppMessage`)}
                    </StatsHeader>
                    <StatsValue>{this.state.wp_phone}</StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
          <Column className="xs-12 sm-12 md-auto">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-green bg-green-opacity">
                    <WhatsApp
                      style={{
                        fontSize: "3em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>
                      {" "}
                      {this.context.t(`sms.credit.wp`)}
                    </StatsHeader>
                    <StatsValue>
                      {" "}
                      {this.context.state.balance?.data?.wp_credit}
                    </StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
        </Grid>

        {(this.state.sender === "" || this.state.sender === null) &&
        !this.state.wp_state ? (
          <>
            {/* <UndefinedSenderAlert /> */}
            <WpUndefinedAlert />
          </>
        ) : (
          <></>
        )}

        <Grid>
          {this.state.step === 0 ? (
            <Column className="xs-12 sm-12 md-6">
              <Grid className="no-gutters-all mb-1">
                <Column className="xs-12 sm-12 md-9">
                  <h3>{this.context.t(`['sms/group'].group.title`)}</h3>
                </Column>
                <Column className="xs-12 sm-12 md-3">
                  <Button
                    title={this.context.t(`['sms/group'].newGroupButtonTitle`)}
                    icon="add"
                    backgroundColor="primary"
                    textColor="white"
                    fullWidth
                    size="sm"
                    onClick={() => {
                      this.setState({ open_new_group_dialog: true });
                    }}
                  />
                </Column>
              </Grid>
              <Grid className="no-gutters-row">
                <Column className="xs-12 sm-12 md-6">
                  <Input
                    label={this.context.t(
                      `['sms/group'].group.searchInputLabel`
                    )}
                    placeholder={this.context.t(
                      `['sms/group'].group.searchInputPlaceholder`
                    )}
                    onChange={async (e) => {
                      await this.setState({ search_key: e.target.value });
                      this.getGroups();
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-6">
                  <Button
                    title={
                      this.state.all_groups
                        ? this.context.t(`['sms/group'].group.unselectAll`)
                        : this.context.t(`['sms/group'].group.selectAll`)
                    }
                    textColor={this.state.all_groups ? "red" : "green"}
                    className="elevation-1"
                    size="lg"
                    fullWidth
                    icon={this.state.all_groups ? "close" : "check"}
                    onClick={() => {
                      this.setState({
                        all_groups: !this.state.all_groups,
                        selected_groups: !this.state.all_groups
                          ? [...this.state.groups]
                          : [],
                        excepted_groups: [],
                      });
                    }}
                  />
                </Column>
              </Grid>

              <Table
                refreshAction={() => this.getGroups(this.state.pagination.page)}
                loaded={this.state.loaded}
                headings={{
                  name: {
                    label: this.context.t(
                      `['sms/group'].group.headings.groupName`
                    ),
                  },
                  customer_count: {
                    label: this.context.t(
                      `['sms/group'].group.headings.customerCount`
                    ),
                  },
                  _: { label: this.context.t(`component.actionHeadingText`) },
                }}
                rows={this.state.groups}
                pagination={this.state.pagination}
                buttons={[
                  {
                    textColor: "green",
                    icon: "check",
                    backgroundColor: "green-opacity",
                    fullWidth: true,
                    pushEffect: true,
                    disabled: (row) =>
                      this.state.all_groups ||
                      (this.state.selected_groups.find(
                        (group) => group.id === row.id
                      )
                        ? true
                        : false),
                    onClick: (row) => {
                      this.setState({
                        selected_groups: [...this.state.selected_groups, row],
                      });
                    },
                  },
                  {
                    icon: "block",
                    textColor: "red",
                    backgroundColor: "red-opacity",
                    fullWidth: true,
                    pushEffect: true,
                    disabled: (row) =>
                      !this.state.all_groups ||
                      (this.state.excepted_groups.find(
                        (excepted) => excepted.id === row.id
                      )
                        ? true
                        : false),
                    onClick: (row) => {
                      this.setState({
                        excepted_groups: [...this.state.excepted_groups, row],
                      });
                    },
                  },
                  {
                    title: this.context.t(
                      `['sms/group'].newGroup.groupEditButtonTitle`
                    ),
                    icon: "edit",
                    textColor: "blue",
                    backgroundColor: "blue-opacity",
                    fullWidth: true,
                    onClick: (row) => {
                      this.setState({
                        open_new_group_dialog: true,
                        new_group: {
                          ...this.state.new_group,
                          edited: true,
                          selected_group: row,
                        },
                      });
                    },
                  },
                ]}
              />
            </Column>
          ) : (
            <this.SMSSettings />
          )}

          <Column
            className={`xs-12 sm-12 ${this.state.step === 0 ? "md-6" : "md-4"}`}
          >
            <Column className="xs-12 sm-12 md-12">
              <h3>{this.context.t(`['sms/group'].approved.title`)}</h3>
              {!this.state.all_groups ? (
                <ChipContainer>
                  {this.state.selected_groups.length > 0 ? (
                    this.state.selected_groups.map((group) => (
                      <Chip
                        key={group.id}
                        style={{ height: 46, borderRadius: 5 }}
                        className="mr-2 mb-2 bg-green-opacity fc-green fw-500"
                        label={group.name}
                        onDelete={() =>
                          this.setState({
                            selected_groups: [
                              ...this.state.selected_groups.filter(
                                (_group) => _group.id !== group.id
                              ),
                            ],
                          })
                        }
                      />
                    ))
                  ) : (
                    <Alert
                      severity="info"
                      color="warning"
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <b>
                        {this.context.t(
                          `['sms/group'].approved.notFound.alertBoldText`
                        )}
                      </b>
                      <br />
                      {this.context.t(
                        `['sms/group'].approved.notFound.alertText`
                      )}
                    </Alert>
                  )}
                </ChipContainer>
              ) : (
                <Alert
                  severity="success"
                  style={{ width: "100%", boxSizing: "border-box" }}
                >
                  <b>
                    {this.context.t(`['sms/group'].approved.alertBoldText`)}
                  </b>
                </Alert>
              )}
            </Column>
            {this.state.all_groups && (
              <Column className="xs-12 sm-12 md-12 mt-2">
                <h3>{this.context.t(`['sms/group'].excepted.title`)}</h3>
                <ChipContainer>
                  {this.state.excepted_groups.length > 0 ? (
                    this.state.excepted_groups.map((group) => (
                      <Chip
                        key={group.id}
                        style={{ height: 46, borderRadius: 5 }}
                        className="mr-2 mb-2 bg-red-opacity fc-red fw-500"
                        label={group.name}
                        onDelete={() =>
                          this.setState({
                            excepted_groups: [
                              ...this.state.excepted_groups.filter(
                                (_group) => _group.id !== group.id
                              ),
                            ],
                          })
                        }
                      />
                    ))
                  ) : (
                    <Alert
                      severity="info"
                      style={{ width: "100%", boxSizing: "border-box" }}
                    >
                      <b>
                        {this.context.t(
                          `['sms/group'].approved.notFound.alertBoldText`
                        )}
                      </b>
                      <br />
                      {this.context.t(
                        `['sms/group'].approved.notFound.alertText`
                      )}
                    </Alert>
                  )}
                </ChipContainer>
              </Column>
            )}
            <Column className="xs-12 sm-12 md-12 mt-2">
              {this.state.step === 0 && (
                <Button
                  className="mt-1"
                  title={this.context.t(`['sms/group'].continueButtonTitle`)}
                  backgroundColor="primary"
                  textColor="white"
                  disabled={
                    this.state.selected_groups.length > 0 ? false : true
                  }
                  fullWidth
                  pushEffect
                  onClick={() => {
                    this.state.selected_groups.length >
                    this.state.excepted_groups.length
                      ? this.setState({ step: 1 })
                      : toast.warning(
                          this.context.t(`['sms/selective'].warningText`)
                        );
                  }}
                />
              )}

              {this.state.step === 1 && (
                <Button
                  className="mt-1"
                  title={this.context.t(
                    `['sms/group'].backToSelectButtonTitle`
                  )}
                  backgroundColor="primary-opacity"
                  textColor="primary"
                  fullWidth
                  pushEffect
                  onClick={() => this.setState({ step: 0 })}
                />
              )}
            </Column>
          </Column>
        </Grid>
        <SMSReview
          open={this.state.open_sms_review_dialog}
          closeHandler={() => {
            this.setState({
              open_sms_review_dialog: false,
            });
          }}
          sendSMS={this.sendSMS}
          total_number={this.state.total_number}
          total_wp_credit={this.state.wp_balance}
          in_use_wp_credit={this.state.total_sms_credit}
          check_wp={this.state.checkedWP}
        />
      </AuthContainer>
    );
  }

  SMSSettings = () => {
    const messageRef = useRef(null);
    const { selected_template, templates, selected_time, hours, send_date } =
      this.state;
    return (
      <Column className="xs-12 sm-12 md-8">
        <Column className="xs-12 sm-12 md-12 lg-2 mt-1">
          <h3>{this.context.t(`sms.headings.content`)}</h3>
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-8">
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={this.state.future_date}
                onChange={async (e) => {
                  await this.setState({
                    future_date: e.target.checked,
                  });
                }}
              />
            }
            label={
              <FCLabel>
                <i className="material-icons">filter_list</i>{" "}
                <span>{this.context.t(`sms.send.futureDate`)}</span>
              </FCLabel>
            }
          />
        </Column>
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
            <Select
              label={this.context.t(`sms.send.template`)}
              items={[...templates]}
              selected={selected_template?.name ?? ""}
              labelKey="name"
              valueKey="name"
              returnFullObject
              handler={(template) =>
                this.setState({
                  selected_template: template,
                  message: template.content,
                })
              }
            />
          </Column>
          {this.state.future_date && (
            <>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                <DatePickerInput
                  label={this.context.t(`sms.send.sendDate`)}
                  value={send_date ? new Date(send_date).toISOString() : ""}
                  onChange={(date) => {
                    this.setState({
                      send_date: new Date(date).toISOString(),
                    });
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                <TimeSelect
                  fullTime={true}
                  label={this.context.t(
                    `['settings/hours'].startInputLabelHour`
                  )}
                  type="hour"
                  value={
                    selected_time !== null ? selected_time.split(":")[0] : ""
                  }
                  fullWidth={true}
                  size="medium"
                  companyShiftTime={true}
                  startHour={0}
                  endHour={24}
                  // minuteStep={5}
                  handler={async (value) => {
                    this.setState({
                      selected_time:
                        selected_time !== null
                          ? `${value}:${selected_time.split(":")[1]}`
                          : `${value}:00`,
                    });
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                <TimeSelect
                  label={this.context.t(
                    `['settings/hours'].startInputLabelMinute`
                  )}
                  type="minute"
                  value={
                    selected_time !== null ? selected_time.split(":")[1] : ""
                  }
                  selectedHour={selected_time}
                  lastHour={hours[hours.length - 1]}
                  fullWidth={true}
                  size="medium"
                  handler={async (time) => {
                    this.setState({
                      selected_time:
                        selected_time !== null
                          ? `${selected_time.split(":")[0]}:${time}`
                          : null,
                    });
                  }}
                />
              </Column>
            </>
          )}
        </Grid>
        <Input
          label={this.context.t(`sms.send.messageInputLabel`)}
          inputRef={messageRef}
          multiline={true}
          rows={10}
          value={this.state.message ?? ""}
          helperText={CalculateMessageCharCount(
            this.state.message,
            `${
              this.state.checkedSMS && this.state.checkedWP
                ? "1"
                : !this.state.checkedSMS && this.state.checkedWP
                ? "3"
                : "1"
            } `
          )}
          onChange={(e) =>
            this.setState({
              message: e.target.value,
            })
          }
        />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {" "}
          <Button
            title={this.context.t(`sms.send.autoText.nameSurname`)}
            // style={{ border: "1px solid green" }}
            size="xs"
            outlined
            textColor="primary"
            onClick={() => {
              const cursorPosition = messageRef.current.selectionStart;

              const addedMessage = `${this.state.message.slice(
                0,
                cursorPosition
              )}|${this.context.t("sms.smsCode.name")} ${this.context.t(
                "sms.smsCode.surname"
              )}| ${this.state.message.slice(cursorPosition)}`;

              this.setState({
                message: addedMessage,
              });

              messageRef.current.focus();
            }}
          />
          <UploadInput fileHandlerProp={this.fileHandlerProp} />
        </div>

        <Button
          className="mt-2"
          icon="send"
          title={this.context.t("menuItems.sms.children.sendGroup")}
          backgroundColor="primary"
          textColor="white"
          fullWidth={true}
          disabled={this.state.message.length === 0}
          onClick={() => {
            this.getSMSReview();
          }}
        />
        <AlertDialog
          title={this.context.t(`sms.send.check.title`)}
          open={this.state.open_send_sms_dialog}
          disableBackdropClick={false}
          buttons={[
            {
              title: this.context.t(`sms.send.check.confirmButtonTitle`),
              onClick: () => this.getSMSReview(),
            },
            { title: this.context.t(`sms.send.check.discardButtonTitle`) },
          ]}
          closeHandler={() => {
            this.setState({ open_send_sms_dialog: false });
          }}
        >
          <Alert severity="warning">
            {this.context.t(`sms.send.check.alertText`)}
          </Alert>
        </AlertDialog>
      </Column>
    );
  };

  _AddGroupDialog = () => {
    const [customerName, setCustomerName] = useState(null);

    const allowDrop = (e) => {
      e.preventDefault();
    };
    const drag = (e, customer) => {
      e.dataTransfer.setData("customer", JSON.stringify(customer));
    };
    const drop = (e) => {
      e.preventDefault();
      let data = JSON.parse(e.dataTransfer.getData("customer"));

      let isCustomer = this.state.selected_customers.find(
        (_customer) => _customer.id === data.id
      );

      !isCustomer &&
        data &&
        !this.state.new_group.select_all_customers &&
        this.setState({
          selected_customers: [...this.state.selected_customers, data],
        });
    };

    const onSelectHandler = (e, customer) => {
      if (e.target.checked) {
        this.setState({
          selected_customers: [...this.state.selected_customers, customer],
        });
      } else {
        this.setState({
          selected_customers: [
            ...this.state.selected_customers.filter(
              (_customer) => _customer.id !== customer.id
            ),
          ],
        });
      }
    };

    useEffect(() => {
      this.getCustomers();
      this.state.new_group.edited && this.getGroupDetail();
      this.state.new_group.edited && this.getGroupCustomers();
    }, []);
    return (
      <AlertDialog
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            {!this.state.new_group.edited ? (
              this.context.t(`['sms/group'].newGroup.title`)
            ) : this.state.new_group.name === "" ? (
              <div> </div>
            ) : (
              this.state.new_group.name
            )}
            {this.state.new_group.edited && (
              <Button
                title={this.context.t(
                  `['sms/group'].newGroup.edit.deleteGroupButtonTitle`
                )}
                backgroundColor="red"
                textColor="white"
                onClick={() => {
                  this.deleteGroup();
                }}
              />
            )}
          </div>
        }
        open={this.state.open_new_group_dialog}
        disableBackdropClick={false}
        maxWidth="md"
        fullWidth={true}
        buttonDisabledTimeout
        buttons={[
          {
            title: this.state.new_group.edited
              ? this.context.t(`['sms/group'].newGroup.edit.editButtonTitle`)
              : this.context.t(`['sms/group'].newGroup.createButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            disabledHandleClose: true,
            onClick: () => {
              this.state.new_group.name
                ? this.state.new_group.edited
                  ? this.updateGroup()
                  : this.addNewGroup()
                : toast.error(
                    this.context.t(`['sms/group'].newGroup.emptyGroupName`)
                  );
              this.setState({
                new_group: {
                  pagination: {
                    ...this.state.new_group.pagination,
                    page: 1,
                  },
                },
              });
            },
          },
          {
            title: this.context.t(`['sms/group'].newGroup.cancelButtonTitle`),
            icon: "close",
            textColor: "red",
            backgroundColor: "red-opacity",
          },
        ]}
        closeHandler={() => {
          setCustomerName(null);
          this.setState({
            open_new_group_dialog: false,
            selected_customers: [],
            new_group: {
              ...this.state.new_group,
              edited: false,
              selected_group: false,
              select_all_customers: false,
              name: "",
              pagination: {
                ...this.state.new_group.pagination,
                page: 1,
              },
            },
          });
        }}
      >
        <Divider
          color="primary"
          style={{
            backgroundColor: "#3189ec",
            marginBottom: "20px",
            height: "2px",
          }}
        />
        <Grid className="no-gutters-row">
          <Column className="xs-12 sm-12 md-12">
            <TextField
              required
              style={{
                border: "1px solid #EAEAEA",
                borderRadius: "10px",
                width: "99%",
                marginLeft: "10px",
                marginBottom: "15px",
              }}
              variant="outlined"
              label={this.context.t(`['sms/group'].newGroup.groupName`)}
              value={this.state.new_group.name ?? ""}
              onChange={(e) => {
                this.setState({
                  new_group: {
                    ...this.state.new_group,
                    name: e.target.value,
                  },
                });
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-6">
            <Card>
              <CardContent>
                <CustomerContainer onDragOver={allowDrop}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px 0px",
                    }}
                  >
                    <h3>
                      {this.context.t(`['sms/group'].newGroup.customersTitle`)}
                    </h3>
                  </div>
                  <hr />
                  <TextField
                    label={this.context.t(
                      `['sms/group'].newGroup.searchCustomer`
                    )}
                    placeholder={this.context.t(
                      `['sms/group'].newGroup.searchCustomerDescription`
                    )}
                    variant="outlined"
                    value={customerName ?? ""}
                    style={{
                      border: "1px solid #EAEAEA",
                      borderRadius: "10px",
                      width: "99%",
                    }}
                    onChange={(e) => {
                      setCustomerName(e.target.value);
                      e.target.value
                        ? this.searchCustomersByKey(1, e.target.value)
                        : this.getCustomers();
                    }}
                  />
                  {this.state.customers.length > 0 ? (
                    <div
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignContent: "center",
                        gap: "5px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          this.state.new_group.select_all_customers
                            ? true
                            : false
                        }
                        onChange={async () => {
                          this.setState({
                            new_group: {
                              ...this.state.new_group,
                              select_all_customers:
                                !this.state.new_group.select_all_customers,
                            },
                            selected_customers: [
                              this.state.new_group.select_all_customers
                                ? []
                                : [...this.state.customers],
                            ],
                          });
                        }}
                        //onChange={(e) => onSelectHandler(e, customer)}
                      />
                      <label
                        onClick={() => {
                          this.setState({
                            new_group: {
                              ...this.state.new_group,
                              select_all_customers:
                                !this.state.new_group.select_all_customers,
                            },
                            selected_customers: this.state.new_group
                              .select_all_customers
                              ? []
                              : [...this.state.customers],
                          });
                        }}
                        style={{
                          color: this.state.new_group.select_all_customers
                            ? "#ff006a"
                            : "#3189ec",
                        }}
                      >
                        {this.context.t(
                          `['sms/group'].newGroup.${
                            this.state.new_group.select_all_customers
                              ? "unselectAllButtonTitle"
                              : "selectAllButtonTitle"
                          }`
                        )}
                      </label>
                    </div>
                  ) : (
                    <p>
                      {this.context.t(
                        `['sms/group'].newGroup.notFoundCustomer`
                      )}
                    </p>
                  )}
                  {this.state.customers.length > 0 ? (
                    this.state.customers.map((customer, index) => (
                      <div
                        key={index}
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignContent: "center",
                          gap: "5px",
                        }}
                      >
                        <input
                          key={customer.id}
                          id={customer.id}
                          type="checkbox"
                          // disabled={this.state.new_group.select_all_customers}
                          checked={
                            this.state.new_group.select_all_customers
                              ? true
                              : this.state.selected_customers.find(
                                  (_customer) => _customer.id === customer.id
                                )
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            onSelectHandler(e, customer);
                          }}
                        />
                        <label
                          htmlFor={customer.id}
                          draggable={true}
                          onDragStart={(e) => drag(e, customer)}
                        >
                          {customer.full_name} - {customer.phone}
                        </label>
                      </div>
                    ))
                  ) : (
                    <p>
                      {this.context.t(
                        `['sms/group'].newGroup.notFoundCustomer`
                      )}
                    </p>
                  )}
                </CustomerContainer>
                <PaginationContainer>
                  <div className="pagination p7">
                    <ul>
                      <button
                        disabled={this.state.new_group.pagination.page < 2}
                        onClick={async () => {
                          await this.setState({
                            new_group: {
                              ...this.state.new_group,
                              pagination: {
                                ...this.state.new_group.pagination,
                                page: this.state.new_group.pagination.page - 1,
                              },
                            },
                          });
                          setTimeout(() => {
                            this.getCustomers(
                              this.state.new_group.pagination.page
                            );
                          }, 300);
                        }}
                      >
                        <li>
                          <ArrowBackIosIcon
                            className="icon-back"
                            fontSize="small"
                          />
                        </li>
                      </button>
                      <button
                        disabled={this.state.customers.length < 10}
                        onClick={async () => {
                          await this.setState({
                            new_group: {
                              ...this.state.new_group,
                              pagination: {
                                ...this.state.new_group.pagination,
                                page: this.state.new_group.pagination.page + 1,
                              },
                            },
                          });
                          setTimeout(() => {
                            this.getCustomers(
                              this.state.new_group.pagination.page
                            );
                          }, 300);
                        }}
                      >
                        <li>
                          <ArrowForwardIosIcon fontSize="small" />
                        </li>
                      </button>
                    </ul>
                  </div>
                </PaginationContainer>
              </CardContent>
            </Card>
          </Column>
          <Column className="xs-12 sm-12 md-6">
            <Card style={{ height: "100%" }}>
              {this.state.loader_customer_list ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <img src={loader} width="100" height="100" alt="loading" />
                </div>
              ) : (
                <CardContent style={{ height: "100%" }}>
                  <CustomerContainer
                    onDragOver={allowDrop}
                    onDrop={drop}
                    style={{ height: "100%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        padding: "5px 0px",
                      }}
                    >
                      <h3>
                        {this.context.t(
                          `['sms/group'].newGroup.selectedCustomersTitle`
                        )}
                      </h3>
                      <IconButton
                        style={{ position: "absolute", right: "10px" }}
                        color="secondary"
                        title={this.context.t(
                          `['sms/group'].newGroup.deleteAllButtonTitle`
                        )}
                        disabled={
                          this.state.selected_customers.length > 0
                            ? false
                            : true
                        }
                        onClick={() => {
                          this.setState({ selected_customers: [] });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                    <hr />
                    {!this.state.new_group.select_all_customers && (
                      <AutoSizer>
                        {({ height, width }) => (
                          <List
                            width={width}
                            height={height - 100}
                            rowCount={this.state.selected_customers.length}
                            rowHeight={40} // Chip yüksekliğine göre ayarlanabilir
                            rowRenderer={this._rowRenderer}
                          />
                        )}
                      </AutoSizer>
                    )}

                    {this.state.new_group.select_all_customers && (
                      <Alert
                        severity="success"
                        style={{ width: "100%", boxSizing: "border-box" }}
                      >
                        <b>
                          {this.context.t(
                            `['sms/group'].newGroup.alertBoldText`
                          )}
                        </b>
                      </Alert>
                    )}
                  </CustomerContainer>
                </CardContent>
              )}
            </Card>
          </Column>
        </Grid>
      </AlertDialog>
    );
  };
}

export default Group;

const CustomerContainer = styled.div`
  //max-height: 600px;
  min-height: 350px;
  // overflow-y: auto;
`;

const ChipContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const PaginationContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatsCardContainer = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-height: 73px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`;

const IconAlignContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 5px;
`;

const TextAlignContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding: 16px;
  }
`;

const StatsHeader = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #aaa;
  padding-bottom: 10px;
`;

const StatsTitle = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #a0a0a0;
`;

const StatsValue = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
`;
